<template>
  <div class="ud-body">
    <a-card :bordered="true">
      <a-form
        ref="form"
        :rules="rules"
        :label-col="labelCol"
        :model="form"
        :wrapper-col="wrapperCol"
        class="form"
      >
        <a-row>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="公告标题" name="noticeTitle">
              <a-input
                placeholder="请输入公告标题"
                v-model:value="form.noticeTitle"
              ></a-input> </a-form-item
          ></a-col>
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="公告状态" name="noticeState">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择公告状态"
              >
                <a-select-option value="0">创建</a-select-option>
                <a-select-option value="1">上线</a-select-option>
                <a-select-option value="2">下线</a-select-option>
              </a-select>
            </a-form-item></a-col
          >
          <a-col :lg="8" :md="12" :sm="24" :xs="24">
            <a-form-item label="所属运营商" name="operator">
              <a-select
                v-model:value="form.operator"
                placeholder="请选择所属运营商"
              >
                <a-select-option value="0"
                  >厦门远丞科技有限公司</a-select-option
                >
              </a-select>
            </a-form-item></a-col
          >
        </a-row>
        <a-row type="flex" justify="end">
          <a-space class="btn-wrap">
            <a-button type="primary" @click="reload"
              ><i class="iconfont icon-zaitu"></i>查询</a-button
            >
            <a-button @click="reset">
              <i class="iconfont icon-mimazhongzhi"></i>重置</a-button
            >
          </a-space>
        </a-row>
      </a-form></a-card
    >
    <a-card :bordered="true" class="table">
      <div>
        <ud-pro-table
          ref="table"
          :rowKey="(record) => record.noticeId"
          :columns="columns"
          :datasource="data"
          @change="handleChange"
          :scroll="{ x: 'max-content' }"
        >
          <template #toolbar>
            <a-space style="margin: 0 20px">
              <a-button type="primary" @click="openEdit()">
                <i class="iconfont icon-daochu" style="margin-right: 5px"></i>
                <span>新建</span>
              </a-button>
            </a-space>
          </template>
          <template #action="{ record }">
            <a-space>
              <a @click="openDetail(record)">详情</a>
              <a @click="openEdit(record)">修改</a>
              <a-divider type="vertical" />
              <a-popconfirm title="确定要删除此项目吗？">
                <a class="ud-text-danger">删除</a>
              </a-popconfirm>
            </a-space>
          </template>
          <template #customTitle>
            <div>
              所属运营商
              <i
                class="iconfont icon-bukejian"
                style="cursor: pointer"
                v-if="!isShowOperator"
                @click="isShowOperator = true"
              ></i>
              <i
                class="iconfont icon-kejian"
                style="cursor: pointer"
                v-else
                @click="isShowOperator = false"
              ></i>
            </div>
          </template>
          <template #operatorCustom="{ record }">
            <div v-if="!isShowOperator">
              {{
                record.operator.substring(0, 1) +
                new Array(record.operator.length).join('*')
              }}
            </div>
            <div v-else>
              {{ record.operator }}
            </div>
          </template>
        </ud-pro-table>
      </div>
      <notice-detail
        v-model:visible="showDetail"
        :data="current || {}"
      ></notice-detail>
      <notice-edit v-model:visible="showEdit" :data="current" @done="reload" />
    </a-card>
  </div>
</template>

<script>
import noticeDetail from './notice-detail.vue'
import noticeEdit from './notice-edit.vue'
// import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    noticeDetail,
    noticeEdit
    // DownOutlined,
    // ExclamationCircleOutlined
    // operationDetail,
    // operationEdit
  },
  data() {
    return {
      visibleLog: false,
      visibleOrder: false,
      visibleEndTime: false,
      visibleRefund: false,
      visibleBatteryCode: false,
      actionForm: { endTime: '' },
      labelCol: { md: { span: 6 }, sm: { span: 24 } },
      wrapperCol: { md: { span: 16 }, sm: { span: 24 } },
      showDetail: false,
      showEdit: false,
      current: null,
      form: {},
      rules: {},
      //   expand: false,
      isShowOperator: false,
      columns: [
        {
          title: '序号',
          width: 48,
          // align: 'center',
          customRender: ({ text, record, index }) => index + 1
        },
        {
          title: '公告标题',
          // width: 170,
          // align: 'center',
          dataIndex: 'noticeTitle'
        },
        {
          title: '公告状态',
          // width: 85,
          // align: 'center',
          dataIndex: 'noticeState'
        },
        {
          // width: 175,
          // align: 'center',
          dataIndex: 'operator',
          slots: {
            title: 'customTitle',
            customRender: 'operatorCustom'
          }
        },
        {
          title: '操作',
          width: 200,
          dataIndex: 'action',
          align: 'center',
          slots: {
            customRender: 'action'
          }
        }
      ],
      data: [
        {
          noticeTitle: '测试标题1',
          noticeId: 'NT792384923234',
          noticeState: '创建',
          operator: '厦门远丞科技有限公司'
        },
        {
          noticeId: 'NT792384923235',
          noticeTitle: '测试标题1',
          noticeState: '创建',
          operator: '厦门远丞科技有限公司'
        }
      ]
    }
  },
  methods: {
    openDetail(row) {
      this.current = row
      this.showDetail = true
    },
    openEdit(row) {
      this.current = row
      this.showEdit = true
    },
    // 新增计费模板确定按钮回调事件
    handleBillingOk() {
      this.$refs.billingForm.validate().then((res) => {
        console.log(res)
        this.$message.success('添加计费模板成功！')
        this.visible = false
      })
    },
    // 是否展开表单
    expandForm() {
      this.expand = !this.expand
    },
    // 表格改变
    handleChange() {}
  }
}
</script>
<style lang='less' scoped>
.form {
  .btn-wrap {
    padding-right: 20px;
    .iconfont {
      margin-right: 5px;
    }
  }
}
.table {
  margin: 10px 0;
}
</style>
